import { createSignal, Show } from 'solid-js';
import { PaymentElement, useElements, useStripe } from 'solid-stripe';
import { PAY, RENTAL_APPLICATION_FEE_BLURB, SOMETHING_WENT_WRONG } from '~/assets/strings';
import { Button } from '~/components/common/buttons';
import { useLocalization } from '~/hooks/useLocalization';
import { ProcessingStripePayment } from './ProcessingStripePayment';

export interface StripeProps {
  amount: string;
  onSuccess: () => void;
  onError: (error: Error) => void;
}

export const StripePayment = (props: StripeProps) => {
  const [isProcessing, setIsProcessing] = createSignal(false);

  const stripe = useStripe();
  const elements = useElements();
  const { t } = useLocalization();

  const handleSubmit = async () => {
    const currentStripe = stripe();
    const currentElements = elements();
    if (!currentStripe || !currentElements) {
      return;
    }
    setIsProcessing(true);
    try {
      const result = await currentStripe.confirmPayment({
        elements: currentElements,
        redirect: 'if_required',
      });
      if (result.error) {
        console.error(result.error);
        props.onError(new Error(result.error.message));
      } else {
        props.onSuccess();
      }
    } catch (e) {
      props.onError(new Error(t(SOMETHING_WENT_WRONG)));
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div class="relative">
      <div class="m-5">{t(RENTAL_APPLICATION_FEE_BLURB, { amount: props.amount })}</div>
      <div class="flex min-h-[300px] flex-col bg-white p-6">
        <PaymentElement />
      </div>
      <div class="sticky bottom-0 left-0 w-full border-t border-gray-200 bg-white p-4">
        <div class="flex items-end justify-end">
          <Button id="pay" type="button" onClick={handleSubmit}>
            {t(PAY)}
          </Button>
        </div>
      </div>
      <Show when={isProcessing()}>
        <ProcessingStripePayment />
      </Show>
    </div>
  );
};
